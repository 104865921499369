<template>
  <div class="gestion-organisme-new-th">
    <div class="box-setting-header">
      <div class="title ml-4 mt-2">Gestion des organismes</div>
      <div>
        <b-button
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addOrganismeNew
        >
          <font-awesome-icon icon="plus" /> Ajouter un organisme
        </b-button>
        <b-modal
          id="addOrganismeNew"
          ref="addOrganismeNew"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-frais"
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un organisme</div>
            <div
              class="iconClose"
              @click.prevent="hideModal('addOrganismeNew')"
            >
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitOrganismeNew"
                class="form-modal-custom-style"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="row ml-1">
                      <b-form-group
                        label="Organisme"
                        label-for="organisme"
                        class="input-modal-champ mb-0 col-3"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="organisme"
                          v-model="organismeNewToAdd.organisme"
                          :options="computedListOrganisme"
                          class="b-form-select-raduis"
                          text-field="name"
                          value-field="id"
                          @change="checkErrorsAdd"
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group
                        label="Group"
                        label-for="group"
                        class="input-modal-champ mb-0 col-3"
                      >
                        <b-form-select
                          autocomplete="off"
                          id="group"
                          v-model="organismeNewToAdd.group"
                          :options="computedListGroup"
                          class="b-form-select-raduis"
                          text-field="name"
                          value-field="id"
                          @change="checkErrorsAdd"
                        ></b-form-select>
                      </b-form-group>

                      <b-form-group
                        label="Date de début"
                        label-for="dateDebut"
                        class="input-modal-champ p-1 col-3"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="organismeNewToAdd.date_debut"
                          :clear-button="true"
                          @input="checkErrorsAdd"
                          :disabled-date="disabledStartDate"
                        ></date-picker>
                      </b-form-group>
                      <b-form-group
                        label="Date de fin"
                        label-for="dateFin"
                        class="input-modal-champ p-1 col-3"
                      >
                        <date-picker
                          value-type="format"
                          type="date"
                          v-model="organismeNewToAdd.date_fin"
                          :clear-button="true"
                          @input="checkErrorsAdd"
                          :disabled-date="disabledEndDate"
                        ></date-picker>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <div class="messageError">
                        <div v-if="errorDupplicate" class="error">
                          <ul v-if="Array.isArray(errorDupplicate)">
                            <li
                              v-for="(e, index) in errorDupplicate"
                              :key="index"
                            >
                              {{ e }}
                            </li>
                          </ul>
                          <div v-else>{{ errorDupplicate }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef prime"
                        label-for="label_commercial_prime"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="label_commercial_prime"
                          v-model="organismeNewToAdd.label_commercial_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef  prime"
                        label-for="coef_commercial_prime"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="coef_commercial_prime"
                          v-model="organismeNewToAdd.coef_commercial_prime"
                          autocomplete="off"
                          disabled
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva Coef prime"
                        label-for="tva_coef_commercial_prime"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_commercial_prime"
                          type="number"
                          step="any"
                          v-model="organismeNewToAdd.tva_coef_commercial_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description Coef prime"
                        label-for="description__commercial_prime"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          :class="'textarea-societe'"
                          id="description__commercial_prime"
                          rows="3"
                          v-model="
                            organismeNewToAdd.description__commercial_prime
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label complémentaire"
                        label-for="label_missing_prime"
                        class="input-modal-champ  p-1 col-6"
                      >
                        <b-form-input
                          id="label_missing_prime"
                          v-model="organismeNewToAdd.label_missing_prime"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Description complémentaire"
                        label-for="description_missing_prime"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-textarea
                          class="textarea-societe "
                          id="description_missing_prime"
                          rows="2"
                          v-model="organismeNewToAdd.description_missing_prime"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>

                    <div class="row ml-1">
                      <b-form-group
                        label="Label complémentaire avoir"
                        label-for="label_missing_prime_av"
                        class="input-modal-champ  p-1 col-6"
                      >
                        <b-form-input
                          id="label_missing_prime_av"
                          v-model="organismeNewToAdd.label_missing_prime_av"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Description complémentaire avoir"
                        label-for="description_missing_prime_av"
                        class="input-modal-champ p-1 col-6"
                      >
                        <b-form-textarea
                          class="textarea-societe "
                          id="description_missing_prime_av"
                          rows="2"
                          v-model="
                            organismeNewToAdd.description_missing_prime_av
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="row ml-1">
                      <b-form-group
                        label="Coef Prime Installateur"
                        label-for="coef_prime_installateur"
                        class="input-modal-champ  p-1 col-6"
                      >
                        <b-form-input
                          id="coef_prime_installateur"
                          type="number"
                          step="any"
                          v-model="organismeNewToAdd.coef_prime_installateur"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva Coef Prime Installateur"
                        label-for="tva_coef_prime_installateur"
                        class="input-modal-champ  p-1 col-6"
                      >
                        <b-form-input
                          id="tva_coef_prime_installateur"
                          v-model="
                            organismeNewToAdd.tva_coef_prime_installateur
                          "
                          autocomplete="off"
                          disabled
                          required
                        ></b-form-input>
                      </b-form-group>
                    </div>

                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef commission commercial group"
                        label-for="label_commercial_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="label_commercial_group"
                          v-model="organismeNewToAdd.label_commercial_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef commission commercial group"
                        label-for="coef_commercial_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="coef_commercial_group"
                          v-model="organismeNewToAdd.coef_commercial_group"
                          autocomplete="off"
                          type="number"
                          step="any"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva coef commission commercial groupe"
                        label-for="tva_coef_commercial_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_commercial_group"
                          v-model="organismeNewToAdd.tva_coef_commercial_group"
                          autocomplete="off"
                          type="number"
                          step="any"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description coef commission commercial groupe"
                        label-for="description_commercial_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          class="textarea-societe "
                          id="description_commercial_group"
                          rows="3"
                          v-model="
                            organismeNewToAdd.description_commercial_group
                          "
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="row ml-1">
                      <b-form-group
                        label="Label Coef surprime group"
                        label-for="label_surprime_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="label_surprime_group"
                          v-model="organismeNewToAdd.label_surprime_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        label="Coef surprime group"
                        label-for="coef_surprime_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="coef_surprime_group"
                          v-model="organismeNewToAdd.coef_surprime_group"
                          autocomplete="off"
                          required
                          type="number"
                          step="any"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Tva coef surprime groupe"
                        label-for="tva_coef_surprime_group"
                        class="input-modal-champ  p-1 col-3"
                      >
                        <b-form-input
                          id="tva_coef_surprime_group"
                          v-model="organismeNewToAdd.tva_coef_surprime_group"
                          autocomplete="off"
                          required
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        label="Description coef surprime groupe"
                        label-for="description_surprime_group"
                        class="input-modal-champ p-1 col-3"
                      >
                        <b-form-textarea
                          class="textarea-societe "
                          id="description_surprime_group"
                          rows="3"
                          v-model="organismeNewToAdd.description_surprime_group"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li
                          v-for="(e, index) in error"
                          :key="index"
                          style="list-style-type: none;"
                        >
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style "
                    :disabled="!validateToAddconf"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="box-all-filter">
      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Organisme</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filterOrganisme"
          :options="[{ id: null, name: 'Tous' }, ...computedListOrganisme]"
          text-field="name"
          value-field="id"
          @change="handleChangeOrganisme"
        ></b-form-select>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Group</span>
        </div>
        <b-form-select
          class="b-form-select-new-style"
          v-model="filterGroup"
          :options="[{ id: null, name: 'Tous' }, ...computedListGroup]"
          text-field="name"
          value-field="id"
          @change="handleChangeGroup"
        ></b-form-select>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Date début</span>
        </div>
        <date-picker
          value-type="format"
          type="date"
          v-model="dateStartForSearch"
          :clear-button="true"
          @input="handleChangeDate_debut"
          :disabled-date="disabledStartDateFilter"
        ></date-picker>
      </div>

      <div class="box-label-champ mr-2">
        <div class="label-box-style">
          <span class="title-tabel">Date fin</span>
        </div>
        <date-picker
          value-type="format"
          type="date"
          v-model="dateEndForSearch"
          :clear-button="true"
          @input="handleChangeDate_fin"
          :disabled-date="disabledEndDateFiltre"
        ></date-picker>
      </div>
      <div v-if="getOrganismeThNewLoading" class="chargement-message-text ml-2">
        Chargement en cours
      </div>
      <div class="error-message">
        <div v-if="getOrganismeThNewError" class="error">
          <ul v-if="Array.isArray(getOrganismeThNewError)">
            <li v-for="(e, index) in getOrganismeThNewError" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getOrganismeThNewError }}</div>
        </div>
      </div>
    </div>

    <div class="organisme-new-body">
      <div class="table-rapport-style organisme-details w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-organisme-new"
          :items="getOrganismeThNew"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          empty-text="Il n'y a aucun enregistrement à afficher"
          tbody-tr-class="ligneNormale"
        >
          <template v-slot:cell(Actions)="data">
            <b-button
              class="button-succes-style ml-2"
              size="sm"
              variant="succes"
              @click.prevent.stop="handleUpdate(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              class="button-danger-style ml-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="deleteOrganismeNew(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              class="btn-secondary btn-sm ml-2"
              @click.prevent.stop="DupplicateOrganismeNew(data.item)"
            >
              <font-awesome-icon icon="clone" />
            </b-button>
          </template>
          <template v-slot:cell(organisme)="data">
            {{ data.item.organisme.name }}
          </template>
          <template v-slot:cell(group)="data">
            {{ data.item.group.name }}
          </template>
          <template v-slot:cell(date_debut)="data">
            {{ data.item.date_debut }}
          </template>
          <template v-slot:cell(date_fin)="data">
            {{ data.item.date_fin ? data.item.date_fin : '-' }}
          </template>

          <template v-slot:cell(coef_prime_installateur)="data">
            {{ data.item.coef_prime_installateur }}
          </template>

          <template v-slot:cell(coef_commercial_prime)="data">
            {{ data.item.coef_commercial_prime }}
          </template>

          <template v-slot:cell(coef_commercial_group)="data">
            {{ data.item.coef_commercial_group }}
          </template>

          <template v-slot:cell(coef_surprime_group)="data">
            {{ data.item.coef_surprime_group }}
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowOrganismeThNew"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="deleteOrganismeThNewModal"
      id="deleteOrganismeThNewModal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un organisme</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteOrganismeThNewModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form
        @submit.prevent="handleSuprimerOrganismeNew"
        class="form-modal-custom-style"
      >
        <div class="mt-4 ml-3">
          <p>
            Êtes-vous sur de vouloir supprimer cet organisme
            <strong>
              {{ selectedOrganisme ? selectedOrganisme.organisme.name : '-' }}
            </strong>
            ?
          </p>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mt-1">
            <span>
              Valider
              <div v-if="getOrganismeThNewLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-update-organisme-params"
      ref="modal-update-organisme-params"
      title="Configuration"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Modifier un organisme</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('modal-update-organisme-params')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitUpdateOrganismeNew"
            v-if="organismeNewToUpdate"
            class="form-modal-custom-style"
          >
            <div class="row">
              <div class="col-12">
                <div class="row ml-1">
                  <b-form-group
                    label="Organisme"
                    label-for="organisme"
                    class="input-modal-champ mb-0 col-3"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="organisme"
                      v-model="organismeNewToUpdate.organisme.id"
                      :options="computedListOrganisme"
                      class="b-form-select-raduis"
                      text-field="name"
                      value-field="id"
                      @change="checkErrorsUpdate"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Group"
                    label-for="group"
                    class="input-modal-champ mb-0 col-3"
                  >
                    <b-form-select
                      autocomplete="off"
                      id="group"
                      v-model="organismeNewToUpdate.group.id"
                      :options="computedListGroup"
                      class="b-form-select-raduis"
                      text-field="name"
                      value-field="id"
                      @change="checkErrorsUpdate"
                    ></b-form-select>
                  </b-form-group>

                  <b-form-group
                    label="Date de début"
                    label-for="dateDebut"
                    class="input-modal-champ p-1 col-3"
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="organismeNewToUpdate.date_debut"
                      :clear-button="true"
                      :disabled-date="disabledStartDateUpdate"
                      required
                    ></date-picker>
                  </b-form-group>

                  <b-form-group
                    label="Date de fin"
                    label-for="dateFin"
                    class="input-modal-champ p-1 col-3"
                  >
                    <date-picker
                      value-type="format"
                      type="date"
                      v-model="organismeNewToUpdate.date_fin"
                      :clear-button="true"
                      :disabled-date="disabledEndDateUpdate"
                    ></date-picker>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <div class="row ml-1">
                    <div class="messageError">
                      <div v-if="errorDupplicate" class="error">
                        <ul v-if="Array.isArray(errorDupplicate)">
                          <li
                            v-for="(e, index) in errorDupplicate"
                            :key="index"
                          >
                            {{ e }}
                          </li>
                        </ul>
                        <div v-else>{{ errorDupplicate }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef prime"
                    label-for="label_commercial_prime"
                    class="input-modal-champ  p-1 col-4"
                  >
                    <b-form-input
                      id="label_commercial_prime"
                      v-model="organismeNewToUpdate.label_commercial_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef prime"
                    label-for="coef_commercial_prime"
                    class="input-modal-champ  p-1 col-2"
                  >
                    <b-form-input
                      id="coef_commercial_prime"
                      v-model="organismeNewToUpdate.coef_commercial_prime"
                      autocomplete="off"
                      disabled
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva Coef prime"
                    label-for="tva_coef_commercial_prime"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_commercial_prime"
                      v-model="organismeNewToUpdate.tva_coef_commercial_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description Coef prime"
                    label-for="description__commercial_prime"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description__commercial_prime ==
                          null
                      }"
                      id="description__commercial_prime"
                      rows="3"
                      v-model="
                        organismeNewToUpdate.description__commercial_prime
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label complémentaire"
                    label-for="label_missing_prime"
                    class="input-modal-champ  p-1 col-6"
                  >
                    <b-form-input
                      id="label_missing_prime"
                      v-model="organismeNewToUpdate.label_missing_prime"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Description complémentaire"
                    label-for="description_missing_prime"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_missing_prime == null
                      }"
                      id="description_missing_prime"
                      rows="2"
                      v-model="organismeNewToUpdate.description_missing_prime"
                    ></b-form-textarea>
                  </b-form-group>
                </div>

                <div class="row ml-1">
                  <b-form-group
                    label="Label complémentaire avoir"
                    label-for="label_missing_prime_av"
                    class="input-modal-champ  p-1 col-6"
                  >
                    <b-form-input
                      id="label_missing_prime_av"
                      v-model="organismeNewToUpdate.label_missing_prime_av"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Description complémentaire avoir"
                    label-for="description_missing_prime_av"
                    class="input-modal-champ p-1 col-6"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_missing_prime_av ==
                          null
                      }"
                      id="description_missing_prime_av"
                      rows="2"
                      v-model="
                        organismeNewToUpdate.description_missing_prime_av
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>

              <div class="col-12">
                <div class="row ml-1">
                  <b-form-group
                    label="Coef Prime Installateur"
                    label-for="coef_prime_installateur"
                    class="input-modal-champ  p-1 col-6"
                  >
                    <b-form-input
                      id="coef_prime_installateur"
                      type="number"
                      step="any"
                      v-model="organismeNewToUpdate.coef_prime_installateur"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva Coef Prime Installateur"
                    label-for="tva_coef_prime_installateur"
                    class="input-modal-champ  p-1 col-6"
                  >
                    <b-form-input
                      id="tva_coef_prime_installateur"
                      v-model="organismeNewToUpdate.tva_coef_prime_installateur"
                      autocomplete="off"
                      disabled
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef commercial group"
                    label-for="label_commercial_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="label_commercial_group"
                      v-model="organismeNewToUpdate.label_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef commercial group"
                    label-for="coef_commercial_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="coef_commercial_group"
                      v-model="organismeNewToUpdate.coef_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva coef commercial groupe"
                    label-for="tva_coef_commercial_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_commercial_group"
                      v-model="organismeNewToUpdate.tva_coef_commercial_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description coef commercial groupe"
                    label-for="description_commercial_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_commercial_group ==
                          null
                      }"
                      id="description_commercial_group"
                      rows="3"
                      v-model="
                        organismeNewToUpdate.description_commercial_group
                      "
                    ></b-form-textarea>
                  </b-form-group>
                </div>
                <div class="row ml-1">
                  <b-form-group
                    label="Label Coef surprime group"
                    label-for="label_surprime_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="label_surprime_group"
                      v-model="organismeNewToUpdate.label_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Coef surprime group"
                    label-for="coef_surprime_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="coef_surprime_group"
                      v-model="organismeNewToUpdate.coef_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Tva coef surprime groupe"
                    label-for="tva_coef_surprime_group"
                    class="input-modal-champ  p-1 col-3"
                  >
                    <b-form-input
                      id="tva_coef_surprime_group"
                      v-model="organismeNewToUpdate.tva_coef_surprime_group"
                      autocomplete="off"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Description coef surprime groupe"
                    label-for="description_surprime_group"
                    class="input-modal-champ p-1 col-3"
                  >
                    <b-form-textarea
                      :class="{
                        'textarea-societe':
                          organismeNewToUpdate.description_surprime_group ==
                          null
                      }"
                      id="description_surprime_group"
                      rows="3"
                      v-model="organismeNewToUpdate.description_surprime_group"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li
                      v-for="(e, index) in error"
                      :key="index"
                      style="list-style-type: none;"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style ">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'Gestion-des-organismes',
  data() {
    return {
      organismeNewToAdd: {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: 0,
        tva_coef_commercial_prime: 20,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null
      },
      dateStartForSearch: null,
      dateEndForSearch: null,
      error: null,
      loading: false,
      selectedOrganisme: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 }
      ],
      fields: [
        { key: 'organisme', label: 'Organisme' },
        { key: 'group', label: 'Group' },
        { key: 'date_debut', label: 'Date début' },
        { key: 'date_fin', label: 'Date fin' },
        { key: 'coef_prime_installateur', label: 'Coef Prime Installateur' },
        { key: 'coef_commercial_prime', label: 'Coef commercial prime' },
        { key: 'coef_commercial_group', label: 'Coef commercial group' },
        { key: 'coef_surprime_group', label: 'Coef surprime group' },
        { key: 'Actions', label: 'Actions' }
      ],
      objToSend: {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      },
      // organismeNewToUpdate: {
      //   organisme: null,
      //   group: null,
      //   coef_prime_installateur: null,
      //   tva_coef_prime_installateur: 0,
      //   coef_commercial_prime: 0,
      //   tva_coef_commercial_prime: 20,
      //   coef_commercial_group: null,
      //   tva_coef_commercial_group: 20,
      //   coef_surprime_group: null,
      //   tva_coef_surprime_group: 20,
      //   date_debut: null,
      //   date_fin: null,
      //   label_commercial_prime: null,
      //   description__commercial_prime: null,
      //   label_commercial_group: null,
      //   description_commercial_group: null,
      //   label_surprime_group: null,
      //   description_surprime_group: null,
      //   label_missing_prime: null,
      //   description_missing_prime: null,
      //   label_missing_prime_av: null,
      //   description_missing_prime_av: null
      // },
      organismeNewToUpdate: null,
      filterOrganisme: null,
      filterGroup: null,
      filterDate_debut: null,
      filterDate_fin: null,
      ListOrganisme: [],
      ListGroup: [],
      validateToAddconf: false,
      errorDupplicate: null
    };
  },
  computed: {
    ...mapGetters([
      'getOrganismeThNew',
      'getOrganismeThNewLoading',
      'getOrganismeThNewError',
      'getSettingFilialesTh',
      'getTotalRowOrganismeThNew',
      'getOrganismeThNewErrorDupplication'
    ]),

    computedListOrganisme() {
      let tab = this.getSettingFilialesTh.filter(
        element => element.type === 'organisme'
      );

      tab = tab.map(item => ({
        id: item.id,
        name: item.name
      }));
      return tab;
    },
    computedListGroup() {
      const tab = this.getSettingFilialesTh.map(item => ({
        id: item.id,
        name: item.name
      }));

      // const tab = this.getSettingFilialesTh.find(
      //   element => element.type === 'filiale'
      // );
      return tab;
    }
  },
  methods: {
    ...mapActions([
      'fetchAllOrganismeThNew',
      'addNewOrganismeThNew',
      'EditOrganismeTh',
      'deletOrganismeThNew',
      'getSettingFilialeTh',
      'checkPeriodOrganism'
    ]),
    disabledStartDate(date) {
      return (
        this.organismeNewToAdd.date_fin &&
        date >= new Date(this.organismeNewToAdd.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.organismeNewToAdd.date_debut &&
        date < new Date(this.organismeNewToAdd.date_debut)
      );
    },

    disabledStartDateFilter(date) {
      return this.dateEndForSearch && date >= new Date(this.dateEndForSearch);
    },
    disabledEndDateFiltre(date) {
      return (
        this.dateStartForSearch && date < new Date(this.dateStartForSearch)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.organismeNewToUpdate.date_fin &&
        date >= new Date(this.organismeNewToUpdate.date_fin)
      );
    },
    disabledEndDateUpdate(date) {
      return (
        this.organismeNewToUpdate.date_debut &&
        date < new Date(this.organismeNewToUpdate.date_debut)
      );
    },
    resetModal() {
      this.organismeNewToAdd = {
        organisme: null,
        group: null,
        coef_prime_installateur: null,
        tva_coef_prime_installateur: 0,
        coef_commercial_prime: 0,
        tva_coef_commercial_prime: 20,
        coef_commercial_group: null,
        tva_coef_commercial_group: 20,
        coef_surprime_group: null,
        tva_coef_surprime_group: 20,
        date_debut: null,
        date_fin: null,
        label_commercial_prime: null,
        description__commercial_prime: null,
        label_commercial_group: null,
        description_commercial_group: null,
        label_surprime_group: null,
        description_surprime_group: null,
        label_missing_prime: null,
        description_missing_prime: null,
        label_missing_prime_av: null,
        description_missing_prime_av: null
      };
      this.error = null;
      this.errorDupplicate = null;
      this.loading = false;
      this.validateToAddconf = false;
      this.objToSend = {
        organisme_id: null,
        group_id: null,
        date_debut: null,
        date_fin: null,
        id: null
      };
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async hendleSubmitOrganismeNew() {
      this.loading = true;
      if (
        this.organismeNewToAdd.date_debut === null ||
        this.organismeNewToAdd.date_debut == ''
      ) {
        this.error = 'date debut is required';
        this.loading = false;
      } else {
        const response = await this.addNewOrganismeThNew(
          this.organismeNewToAdd
        );
        if (response.succes) {
          this.loading = false;
          this.error = null;
          this.errorDupplicate = null;
          this.validateToAddconf = false;
          this.hideModal('addOrganismeNew');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    handleChangeOrganisme() {
      this.handleFilter();
    },
    handleChangeGroup() {
      this.handleFilter();
    },
    handleChangeDate_debut() {
      this.handleFilter();
    },
    handleChangeDate_fin() {
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    handleFilter() {
      this.fetchAllOrganismeThNew({
        per_page: this.perPage,
        page: this.page,
        organisme: this.filterOrganisme,
        group: this.filterGroup,
        date_debut: this.dateStartForSearch,
        date_fin: this.dateEndForSearch
      });
    },
    DupplicateOrganismeNew(item) {
      this.organismeNewToAdd = {
        organisme: item.organisme.id,
        group: item.group.id,
        coef_prime_installateur: item.coef_prime_installateur,
        tva_coef_prime_installateur: item.tva_coef_prime_installateur,
        coef_commercial_prime: item.coef_commercial_prime,
        tva_coef_commercial_prime: item.tva_coef_commercial_prime,
        coef_commercial_group: item.coef_commercial_group,
        tva_coef_commercial_group: item.tva_coef_commercial_group,
        coef_surprime_group: item.coef_surprime_group,
        tva_coef_surprime_group: item.tva_coef_surprime_group,
        date_debut: item.date_debut,
        date_fin: item.date_fin,
        label_commercial_prime: item.label_commercial_prime,
        description__commercial_prime: item.description__commercial_prime,
        label_commercial_group: item.label_commercial_group,
        description_commercial_group: item.description_commercial_group,
        label_surprime_group: item.label_surprime_group,
        description_surprime_group: item.description_surprime_group,
        label_missing_prime: item.label_missing_prime,
        description_missing_prime: item.description_missing_prime,
        label_missing_prime_av: item.label_missing_prime_av,
        description_missing_prime_av: item.description_missing_prime_av,
        id: null
      };
      this.organismeNewToAdd.date_debut = moment(
        this.organismeNewToAdd.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      if (this.organismeNewToAdd.date_fin != null) {
        this.organismeNewToAdd.date_fin = moment(
          this.organismeNewToAdd.date_fin,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
      }
      this.checkErrorsAdd();
      // this.organismeNewToAdd = { ...item };
      this.$refs['addOrganismeNew'].show();
    },
    handleUpdate(item) {
      this.organismeNewToUpdate = { ...item };
      this.$refs['modal-update-organisme-params'].show();
      this.organismeNewToUpdate.date_debut = moment(
        this.organismeNewToUpdate.date_debut,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
      this.organismeNewToUpdate.date_fin = moment(
        this.organismeNewToUpdate.date_fin,
        'DD-MM-YYYY'
      ).format('YYYY-MM-DD');
    },
    deleteOrganismeNew(item) {
      this.selectedOrganisme = item;
      this.$refs['deleteOrganismeThNewModal'].show();
    },
    async handleSuprimerOrganismeNew() {
      const response = this.deletOrganismeThNew(this.selectedOrganisme);
      if (response) {
        this.hideModal('deleteOrganismeThNewModal');
      }
    },
    async handleSubmitUpdateOrganismeNew() {
      this.loading = true;
      if (
        this.organismeNewToUpdate.date_debut === null ||
        this.organismeNewToUpdate.date_debut == ''
      ) {
        this.error = 'date debut is required';
        this.loading = false;
      } else {
        const response = await this.EditOrganismeTh(this.organismeNewToUpdate);

        if (response) {
          this.hideModal('modal-update-organisme-params');
        } else {
          this.loading = false;
        }
      }
    },
    async checkErrorsAdd() {
      var objToSend = {
        organisme_id: this.organismeNewToAdd.organisme
          ? this.organismeNewToAdd.organisme
          : null,
        group_id: this.organismeNewToAdd.group
          ? this.organismeNewToAdd.group
          : null,
        date_debut: this.organismeNewToAdd.date_debut
          ? this.organismeNewToAdd.date_debut
          : null,
        date_fin: this.organismeNewToAdd.date_fin
          ? this.organismeNewToAdd.date_fin
          : null,
        id: null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);
        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    },
    async checkErrorsUpdate() {
      var objToSend = {
        organisme_id: this.organismeNewToUpdate.organisme.id
          ? this.organismeNewToUpdate.organisme.id
          : null,
        group_id: this.organismeNewToUpdate.group.id
          ? this.organismeNewToUpdate.group.id
          : null,
        date_debut: this.organismeNewToUpdate.date_debut
          ? this.organismeNewToUpdate.date_debut
          : null,
        date_fin: this.organismeNewToUpdate.date_fin
          ? this.organismeNewToUpdate.date_fin
          : null,
        id: this.organismeNewToUpdate.id ? this.organismeNewToUpdate.id : null
      };

      if (objToSend.organisme_id != null && objToSend.group_id != null) {
        const response = await this.checkPeriodOrganism(objToSend);

        if (response.success) {
          this.validateToAddconf = true;
          this.errorDupplicate = null;
        } else {
          this.errorDupplicate = response.errorDupplicate;
          this.validateToAddconf = false;
        }
      }
    }
  },
  components: {
    Card: () => import('../component/card'),
    DatePicker
  },
  async mounted() {
    this.getSettingFilialeTh();
    // this.fetchAllOrganismeThNew();
    this.handleFilter();
  }
};
</script>

<style lang="scss" scoped>
.gestion-organisme-new-th {
  height: 100%;
  .tabs-organisme {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .organisme-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .organisme-new-body {
    display: flex;
    margin: 5px 15px;

    height: calc(100vh - 194px) !important;
    .organisme-details {
      width: 100%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 280px) !important;
        height: calc(100vh - 280px) !important;
        .button-succes-style,
        .button-danger-style,
        .btn-secondary {
          border-radius: 6px;
          padding: 3px 5px;
          font-size: 12px;
          width: 25px;
          height: 25px;
          margin: 2px;
        }
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
}
.label-box-style {
  width: 65px;
}

.box-all-filter .mx-datepicker {
  width: 110px;
}

.form-modal-custom-style .form-group .d-block {
  margin-left: 0px !important;
}
</style>
<style lang="scss">
.box-all-filter .mx-input {
  height: 31px !important;
  font-size: 11px !important;
  border: 0px solid #ccc;
  border-radius: 0px 18px 18px 0px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1100px;
  }
}
.table-organisme-new td,
.table-rapport-style th {
  padding: 7px !important;
}
.textarea-societe {
  line-height: 0.5;
}
</style>
